<template>
	<div class="d-inline-block">
		<div :class="{loading:loading}">
			<button-component
				@btn-click="onClick"
				v-bind="$attrs"
			>
				<slot/>
			</button-component>
		</div>
	</div>

</template>

<script>
  import ButtonComponent from "./ButtonComponent";
  export default {
    name: "LoadingButton",
    components: {ButtonComponent},
    props:{
      loading: {
        type: Boolean,
        default: false
      }
    },
    methods:{
      onClick(e){
        this.$emit('btn-click',e)
      }
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";

	.loading{
		padding: .2rem;
		position: relative;
		border-radius: .5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		&::before{
			content: "";
			position: absolute;
			width: 200%;
			height: 300%;
			background-image: conic-gradient(
					transparent, transparent 70deg, $error 120deg, $error 180deg,
					transparent 180deg, transparent 250deg, $error 300deg, $error 360deg
			);
			z-index: -1;
			animation: rotate 1s linear infinite;
		}
	}
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>