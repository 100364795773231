<template>
  <button
      @click.stop.prevent="handleClick"
      class="button"
      :class="{
              [variant]: this.variant
            }"
      v-bind="$attrs"
  >
    <slot>
      {{ text }}
    </slot>
    <div class="disabled-overlay" v-if="$attrs.disabled" @click.stop.prevent="disabledClick"/>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: {
      type: String,
      default: "Ok"
    },
    variant: {
      type: String,
      default: 'main'
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('btn-click', e)
    },
    disabledClick() {
      this.$emit('disabled-click')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/colors";

.button {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  background: $error;
  border-radius: .3rem;
  color: $text-secondary;
  transition: all .2s linear;
  box-shadow: 0 0 0 rgba(var(--box-shadow-color-rgb), 0);
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    box-shadow: 3px 3px 6px 1px rgba(var(--box-shadow-color-rgb), .4);
  }

  &:disabled {
    background: $text-tertiary-middle;
    cursor: default;

    &:hover {
      box-shadow: 3px 3px 6px 1px rgba(var(--box-shadow-color-rgb), 0);
    }
  }
}

.main {
  padding: .7rem 1rem;
}

.transparent {
  background: transparent;
  color: $text-primary;
  box-shadow: none;
  padding: .7rem 1rem;

  &:hover {
    box-shadow: none;
  }
}

.icon {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  background: transparent;

  svg {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.disabled-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>